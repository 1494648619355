<script>
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import List from "./pages/List";
import { mapState } from "vuex";

export default {
  name: "ChatbotIndex",
  mixins: [BaseIndex],
  components: {
    List
  },
  data() {
    return {
      selectedContent: null,
      scope: "sys",
      contentType: "chatbot",
      editable: false
    };
  },
  computed: {
    ...mapState("usages", {
      chatbotUsages: state => state.chatbotUsages
    })
  }
};
</script>

<style scoped></style>
